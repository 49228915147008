<template>
  <Dialog :visible="cargando" :modal="true" :draggable="false" :showHeader="false" class="dialog-no-footer">
    <div class="d-flex align-items-center justify-content-center">
      <ProgressSpinner style="width: 50px; height: 50px;" class="me-3" /> Cargando...
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: "DialogoCargando",
  components: {
    Dialog, ProgressSpinner
  },
  props: {
    cargando: {
      default: false
    }
  }
}
</script>
