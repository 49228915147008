import {useUserStore} from "../stores/user";

const configraciones = {
  get(type, key) {
    const store = useUserStore();
    return store.configuraciones.find(x => x.type === type && x.key === key);
  },
  getAll() {
    const store = useUserStore();
    return store.configuraciones;
  }
};

export default configraciones;

// {{$configuraciones.get(TiposConfiguracion.ENTREGA, KeysConfiguracion.TIEMPO_ANTICIPACION).value}}
