import {useUserStore} from "../stores/user";

const permisos = {
  can(permiso) {
    const store = useUserStore();
    return store.permisos.includes(permiso);
  },
  some(permisos) {
    const store = useUserStore();
    return permisos.some(x => store.permisos.includes(x));
  }
};

export default permisos;
